import React from 'react';
// @ts-ignore
import sadHubert from '../images/404.svg';

export default () => {
  return (
    <div className="error_page_container">
      <div className="error_page_card">
        <img src={sadHubert.src} alt="chattr branded 404" />
        <div className="error_page_divider" />
        <p className="error_page_text">Page Not Found</p>
      </div>
    </div>
  );
};
